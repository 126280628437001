import { createSelector } from 'reselect';

const authStatusCode = state => state.auth.statusCode;
const authApikey = state => state.auth.apikey;
const authUuid = state => state.auth.uuid;
const authIsAuthenticated = state => state.auth.authenticated;// true, false
const authStatus = state => state.auth.status; // valid, restricted

export {
	authStatusCode,
	authApikey,
	authUuid,
    authIsAuthenticated,
	authStatus,
};
