import getText from 'AppUtils/language';

const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
];

const monthNamesShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
];

const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

/**
 * Formats dates
 * More support from here https://gist.github.com/icodeforlove/7866043
 */
const XDateFormatters = {
    format(date, fmt) {
        const formatChr = /%\w/g;
        const formatChrCb = function (t) {
            const key = t.replace('%', '');
            return XDateFormatters[key] ? XDateFormatters[key](date) : t;
        };
        return fmt.replace(formatChr, formatChrCb);
    },

    //year 00 .. 99 ..
    y(date) {
        return this.Y(date).toString().slice(-2);
    },
    //year 1999 .. 2000 ..
    Y(date) {
        return date.getFullYear();
    },

    //Day 0 .. 23
    d(date) {
        return date.getDate().toString().padStart(2, '0');
    },
    //Day 00 .. 01 .. 23
    D(date) {
        return this.d(date).toString().padStart(2, '0');
    },
    // name of the day of the week
	N(date) {
		return weekday[date.getDay()];
	},

    //month 1..12
    m(date) {
        //tbc Cristi + 1
        return (date.getMonth() + 1).toString().padStart(2, '0');
    },
    //month 01..12
    M(date) {
        return this.m(date).toString().padStart(2, '0');
    },

    //month Jan ... Dece
    b(date) {
        return monthNamesShort[parseInt(this.m(date), 10)];
    },
    //month January ... December
    B(date) {
        return monthNames[this.m(date)];
    },

    //Hour
    // 24-Hours; 0..23
    h(date) {
        return date.getHours().toString().padStart(2, '0');
    },
    // 24-Hours w/leading 0; 01..12
    H(date) {
        return this.h(date).toString().padStart(2, '0');
    },

    // 12-Hours 1..12
    g(date) {
        return String(this.h(date) % 12 || 12).padStart(2, '0');
    },
    // 12-Hours w/leading 0; 01..12
    G(date) {
        this.g(date).toString().padStart(2, '0');
    },

    //am/pm
    a(date) {
        return date.getHours() > 11 ? 'pm' : 'am';
    },
    //AM/PM
    A(date) {
        return this.a(date).toUpperCase();
    },

    //Minutes 0 1 ..59
    i(date) {
        return date.getMinutes().toString().padStart(2, '0');
    },
    //Minutes 00 01 ..59
    I(date) {
        return date.i(date).toString().padStart(2, '0');
    },

    //Seconds 0 1 ..59
    s(date) {
        return date.getSeconds();
    },
    //Seconds 00 01 ..59
    S(date) {
        return date.getSeconds().toString().padStart(2, '0');
    },
};

const MINUTE_IN_SECONDS = 60;
const HOUR_IN_SECONDS = 3600;
const DAY_IN_SECONDS = 86400;
const WEEK_IN_SECONDS = 604800;
const MONTH_IN_SECONDS = 2592000;
const YEAR_IN_SECONDS = 31536000;

const SECOND_IN_MS = 1000;
const MINUTE_IN_MS = 60000;
const HOUR_IN_MS = 3600000;
const DAY_IN_MS = 86400000;
const WEEK_IN_MS = 604800000;
const MONTH_IN_MS = 2592000000;
const YEAR_IN_MS = 31536000000;

/**
 *
 */
class XDate {
    /**
     * new XDate()
     * new XDate(new Date('Mon Nov 05 2018 14:05:15 GMT+0200))
     * new XDate(undefined)
     * new XDate(new Date())
     * new XDate(new XDate())
     * new XDate()
     * @param src
     */
    constructor(src = null) {
        if (!src) this.date = new Date();
        else if (typeof src === 'string') this.date = new Date(src);
        else if (typeof src === 'undefined') this.date = new Date();
        else if (src === 'undefined') this.date = new Date();
        else if (src instanceof Date) this.date = new Date(src.toISOString());
        else if (src instanceof XDate) this.date = new Date(src.toISOString());
        else this.date = new Date();
    }

    reset() {
        this.date = new Date();
        return this;
    }

    addYears(years) {
        this.date.setFullYear(this.date.getFullYear() + years);
        return this;
    }

    addDays(days) {
        this.date.setSeconds(this.date.getSeconds() + days * 24 * 60 * 60);
        return this;
    }

    addSeconds(seconds) {
        this.date.setSeconds(this.date.getSeconds() + seconds);
        return this;
    }

    diffYears(compare = null) {
        compare = new XDate(compare);
        return this.date.getFullYear() - compare.date.getFullYear();
    }

    diffDays(compare = null) {
        compare = new XDate(compare);
        return Math.floor(Math.abs((this.date - compare.date) / DAY_IN_MS));
        //return Math.floor(Math.abs((this.date - compare.date)/(24*60*60*1000)));
    }

    diffMinutes(compare = null) {
        compare = new XDate(compare);
        return Math.floor(Math.abs((this.date - compare.date) / MINUTE_IN_MS));
        //return Math.floor(Math.abs((this.date - compare.date)/(60*1000)));
    }
    diffSeconds(compare = null) {
        compare = new XDate(compare);
        return Math.floor(Math.abs((this.date - compare.date) / SECOND_IN_MS));
        //return Math.floor(Math.abs((this.date - compare.date)/1000));
    }

    toISOString() {
        return this.date.toISOString();
    }

    toUTCString() {
        return this.date.toUTCString();
    }

    toString() {
        return this.toISOString();
    }

    getTimestamp() {
        return Math.round(this.date.getTime() / 1000);
    }

    getRelativeTime(compare = null, expression = getText('ago')) {
        const seconds = this.diffSeconds(compare);

        if (seconds > YEAR_IN_SECONDS) { return getText('{0} {0,year|years} {1}').strFormatPlurals(Math.floor(seconds / MONTH_IN_SECONDS), expression); }
        if (seconds > MONTH_IN_SECONDS) { return getText('{0} {0,month|months} {1}').strFormatPlurals(Math.floor(seconds / MONTH_IN_SECONDS), expression); }
        if (seconds > WEEK_IN_SECONDS) { return getText('{0} {0,week|weeks} {1}').strFormatPlurals(Math.floor(seconds / WEEK_IN_SECONDS), expression); }
        if (seconds > DAY_IN_SECONDS) { return getText('{0} {0,day|days} {1}').strFormatPlurals(Math.floor(seconds / DAY_IN_SECONDS), expression); }
        if (seconds > HOUR_IN_SECONDS) { return getText('{0} {0,hour|hours} {1}').strFormatPlurals(Math.floor(seconds / HOUR_IN_SECONDS), expression); }
        if (seconds > MINUTE_IN_SECONDS) { return getText('{0} {0,minute|minutes} {1}').strFormatPlurals(Math.floor(seconds / MINUTE_IN_SECONDS), expression); }
        return getText('{0} {0,second|seconds} {1}').strFormatPlurals(seconds, expression);
    }
    getShortTime(compare = null, expression = getText('ago')) {
        const seconds = this.diffSeconds(compare);

        if (seconds > YEAR_IN_SECONDS) { return this.format('%D %b %Y'); }
        if (seconds > MONTH_IN_SECONDS) { return this.format('%D %b'); }
        if (seconds > DAY_IN_SECONDS) { return this.format('%D %b'); }
        if (seconds > HOUR_IN_SECONDS) {
            return this.format('%G:%I %A');
        }
        if (seconds > MINUTE_IN_SECONDS) { return getText('{0} {0,minute|minutes} {1}').strFormatPlurals(Math.floor(seconds / MINUTE_IN_SECONDS), expression); }
        return getText('few seconds {1}').strFormatPlurals(seconds, expression);
    }

    getHumanDistance(compare = null, expression = getText('ago')) {
        const seconds = this.diffSeconds(compare);

        if (seconds > YEAR_IN_SECONDS) { return this.format('%D %b %Y'); }
        if (seconds > MONTH_IN_SECONDS) { return this.format('%D %b'); }
        if (seconds > DAY_IN_SECONDS) { return this.format('%D %b'); }
        if (seconds > HOUR_IN_SECONDS) {
            return this.format('%G:%I %A');
        }
        if (seconds > MINUTE_IN_SECONDS) { return getText('{0} {0,minute|minutes} {1}').strFormatPlurals(Math.floor(seconds / MINUTE_IN_SECONDS), expression); }
        return getText('{0} {0,second|seconds} {1}').strFormatPlurals(seconds, expression);
    }

    format(fmt) {
        return XDateFormatters.format(this.date, fmt);
    }
}

XDate.getInstance = function (ts = null) {
    return new XDate(ts);
};

export default XDate;
