const AUTH_CHECK = 'AUTH_CHECK';
const AUTH_SET = 'AUTH_SET';
const AUTH_SET_UUID = 'AUTH_SET_UUID';
const AUTH_UPDATE = 'AUTH_UPDATE';
const AUTH_SET_USER = 'AUTH_SET_USER';
const AUTH_RESET = 'AUTH_RESET';
const AUTH_LOGOUT = 'AUTH_LOGOUT';
const AUTH_LOGIN = 'AUTH_LOGIN';

export {
    AUTH_CHECK,
    AUTH_SET,
	AUTH_SET_UUID,
    AUTH_UPDATE,
    AUTH_SET_USER,
    AUTH_RESET,
    AUTH_LOGOUT,
    AUTH_LOGIN,
};
