import { createAction } from 'redux-actions';
import * as AUTH from './types';

const authSet = createAction(AUTH.AUTH_SET, data => data);
const authCheck = createAction(AUTH.AUTH_CHECK, data => data);
const authLogout = createAction(AUTH.AUTH_LOGOUT, data => data);
const authLogin = createAction(AUTH.AUTH_LOGIN);

export {
    authSet,
    authCheck,
    authLogout,
    authLogin,
};
